export default [
  {
    title: 'Natural Beauty',
    name: 'Natural Beauty',
    text:
      '',
  },
  {
    title: 'Increased Vitality',
    name: 'Increased Vitality',
    text:
      "Kim's book is a treasure trove of valuable resources to aid your longevity. ~Isabel Fish (Director, The Isabel Fish Academy) ",
  },
  {
    title: 'Esteem and Appearance',
    name: 'Esteem and Appearance',
    text:
      '',
  },
  {
    title: 'Improved Health',
    name: 'Improved Health',
    text:
      'The more Kim explained the content, the more I began to see the value in it, in fact the knowledge provided is critical for overall well-being. You just need to make the effort to read and engage with it and then you will have “AHA” moments. ~Mari Gordon (Health Psychology Guide and Book)',
  },
  {
    title: 'Enhanced physique',
    name: 'Enhanced physique',
    text:
      '',
  },
  {
    title: 'Advanced Learning',
    name: 'Health Psychology Guide and Book',
    text:
      "If you're serious about wanting a healthier more fulfilling life you need to read Kim's highly researched and comprehensive book 'Health Psychology.' This book is a road map of tools, truths, and resources that will empower you to make major changes that will have a huge impact on your life, to live with optimum health and radically improve the quality of all areas of your life. ~Lance Strong (9th Degree Black Belt, Member of the New Zealand Martial Arts Hall of Fame) ",
  },
  {
    title: 'Life Balance and Connection',
    name: 'Jean Shrimpton',
    text:
      'Since shopping at the Sanctuary Store and eating truly good since shopping at the Sanctuary Store and eating truly good food every day, I cannot believe the difference this has made to my energy levels and work performance. I am definitely recommending Sanctuary to all of my friends. What a great concepts, thank you Sanctuary Store!',
  },                            
  {
    title: 'Empowerment',
    name: 'Empowerment',
    text:
      '',
  },
  {
    title: 'Exquisite taste',
    name: 'Exquisite taste',
    text:'',
  },
  {
    title: 'Clarity of Mind',
    name: 'Clarity of Mind',
    text:
      '',
  },
  {
    title: 'Greater Happiness',
    name: 'Greater Happiness',
    text:
      '',
  },
];
