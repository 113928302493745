import React from 'react';
import './index.scss';

const Index = ({ cn = '', item, uppercase = false, action = false, children }) => {
    
    return (
        <div className={`service_card`} style={{height:"100%"}}>
            <div className={`service_item_front ${cn}`} style={{ backgroundImage: `url(${item.image})`, backgroundPositionY: 'inherit'}}>
                {item?.btn && <div className='service_item_front_btn'>{item?.btn}</div>}
                <p style={{ textTransform: `${uppercase ? 'uppercase' : ''}`}}>{item?.name}<br/>{item?.followingLine}
                {action && <span className='service_item_front_action'>{`>>See guides  >>See workshop`}</span>}
                <a style={{ textTransform: `${uppercase ? 'uppercase' : ''}`}}>{item?.subname}<br/>
                {action && <span className='service_item_front_action'>{`>>See guides  >>See workshop`}</span>}</a>
                </p>
                {item?.subTitle && <div className={`services_item_title ${item?.titColor}`} >{item?.subTitle}</div>}
            </div>  
            <div className={`service_item_back ${cn}`}>
                <p style={{ textTransform: `${uppercase ? 'uppercase' : ''}`}}>{children ? children : item?.name}<br/>{item?.followingLine} </p>
            </div> 
        </div>
    )
}

export default Index;