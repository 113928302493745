import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import BeComeButton from '../../components/BeComeButton/BeComeButton';
import './Footer.scss';

const Footer=()=> {
    const { pathname } = useLocation();
  return (
      <>
    <footer>
  <Container>
  <Row>
            <Col sm={12} md={5} className="dis-cover cols">
                <p className="font-weight-bold">Sign up and become an Urban Sanctuary Visionaire</p>
                <BeComeButton />
                <p className="copyright">&copy; VFH Sanctuaries 2018. The information, designs and concepts contained here is the copyright and intellectual property of Vision for Humanity Charitable Trust</p>
            </Col>

            <Col sm={12} md={3} className=" footer-col cols">
                <div className='m-men'>
                <ul className="footer-menu ">
                    <li><a href="/about-us">About us </a></li>
                    <li><a href="#!">discover</a></li>
                    <li><a href="/service-health">services</a></li>
                    </ul>
                    <ul className="footer-menu">
                    <li><a target="_blank" href="https://sanctuarystore.co.nz/participate">participate</a></li>
                    <li><a target="_blank" href="https://sanctuarystore.co.nz/prosper">prosper</a></li>
                    <li><a href="/get-started">get started</a></li>
                </ul>
                </div>
            </Col>
            {/* <Col sm={12} md={1} className="footer-col cols">
                <ul className="footer-menu font-weight-bold">
                    <li><a href="#">Find a Store </a></li>
                    <li><a href="#">Careers</a></li>
                    <li><a href="#">Volunteer</a></li>
                    <li><a href="#">Know More</a></li>
                </ul>
            </Col> */}
            <Col sm={12} md={3} className="footer-col-2 cols">
                <ul className="footer-inf font-weight-bold">
                    <li className="f-font-famB">URBAN SANCTURIES - VISION FOR HUMANITY</li>
                    <li className="font-weight-light f-font-famL"  style={{ color: pathname === '/store-service-skin-analysis'|| pathname ==='/store-service-inbody-scanning' ? '#9AA200' : '' }}><a href='/account' style={{color: '#007bff'}}>Click here to integrate your Visionaire accounts</a></li>
                    <li>(09) 353-7890</li>
                    <li>Level 3, 2 St Martins Lane,<br/>Prosper Grafton, Auckland 1010<br/>New Zealand</li>
                </ul>
            </Col>
            <Col sm={12} md={1} className="footer-icon" style={{ color: pathname === '/store-service-skin-analysis' || pathname ==='/store-service-inbody-scanning' ? '#9AA200' : '',    display: 'flex',
    justifyContent: 'space-between'}}>
                <i style={{fontSize:28}}  className="fab fa-instagram"
                onClick={() => window.open('https://www.instagram.com/vfhsanctuaries/',"_blank")}
                ></i>
                {/* <i className="fab fa-twitter"></i>
                <i className="fab fa-facebook-f"></i> */}
            </Col>
        </Row>
        </Container>
</footer>
</>
  );
}

export default Footer;
