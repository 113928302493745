import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header/Header";
import { useHistory } from "react-router-dom";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import "./Home.scss";
import "../../assets/styles/responsive.scss";
import Slider from "react-slick";
import Park from "../../assets/images/home-img/park.png";
import Group from "../../assets/images/home-img/Group.png";
import Cart from "../../assets/images/home-img/cart.png";
import Biospherically from "../../assets/images/home-img/biospherically-correct-trans-bg.png";
import leafImg from "../../assets/images/home-img/leaf-img.png";
import card1 from "../../assets/images/home-img/card1.png";
import card2 from "../../assets/images/home-img/card2.png";
import card3 from "../../assets/images/home-img/card3.png";
import card4 from "../../assets/images/home-img/card4.png";
import card5 from "../../assets/images/home-img/card5.png";
import globeHands from "../../assets/images/home-img/globe-hands.png";
import web from "../../assets/images/home-img/web.png";
import map from "../../assets/images/home-img/map.png";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import mobileBg from "../../assets/images/home-img/mobileBg.png";
import btnlogo from "../../assets/images/btnlogo.png";
import Disclaimer from "../../components/disclaimer";
import FooterBanner from "../../components/footer-banner";
import Footer from "../../components/Footer/Footer";
import { useAuth } from "shared/auth";
import { Button } from "@material-ui/core";
import PeopleData from './Sections/peopleData';
import PeopleSection from 'shared/sections/peopleSection';

const Home = () => {
  const history = useHistory();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const { auth } = useAuth();

  const dbLinks = (link) => {
    history.push(link);
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    maxWidth: 50,
  };
  return (
    <div className="homepage-info">
      <Header />
      <div className="main-slider">
        <HomeSlider slide={1}></HomeSlider>
      </div>
      {!auth.token && <div className="user-form">
        <Container>
          <Row className="align-items-center">
            <Col md={4}>
              <div className="btnImgesLbl">
                <Button variant="none">
                <img alt="not found" src={btnlogo} onClick={() => dbLinks("/get-started")}/>
                </Button>
                <p>Create your account today !</p>
              </div>
            </Col>
            <Col md={8}>
              <p className="main-txt">
                Sign up to receive free insights into our human advancement
                training material, access exercise programs, create and save
                meal plans, and later integrate data with our Human Advancement
                Apps once complete.
              </p>

              <div className="form-cover">
                <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    type="text"
                    name="fname"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    placeholder="Full name"
                  />
                </Form.Group>
                <Form.Group className="mb-3 mt-3">
                  <Form.Control
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    placeholder="Email"
                  />
                </Form.Group>
                <button
                  className="signup-btn mt-3 mb-3"
                  onClick={() =>
                    dbLinks(
                      "/get-started?" + "name=" + name + "&email=" + email
                    )
                  }
                >
                  signup
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>}

      <div className="custom-card">
        <Container>
          <Row>
            <Col md={12}>
              <div className="card-info">
                <a className="card-wrapper" href="/master-sanctuary">
                  <img alt="not found" className="card-img" src={Park} />
                  <p className="cards-title">MASTER SANCTUARY</p>
                  <p className="cards-txt">Reconnect with Land and Sea </p>
                  <span className="cards-desc">
                    Master Retreat & Conscious Lifestyle
                    <br />
                    Experience
                  </span>
                </a>
                <a className="card-wrapper dark-card" href="#!">
                  <img alt="not found" className="card-img" src={Group} />
                  <p className="cards-title">URBAN SANCTUARY</p>
                  <p className="cards-txt">Holistic Advancement Architecture</p>
                  <span className="cards-desc">
                    Human Well-being & Advancement <br />
                    Services
                  </span>
                </a>
                <a
                  className="card-wrapper"
                  href="https://sanctuarystore.co.nz/"
                >
                  <img alt="not found" className="card-img" src={Cart} />
                  <p className="cards-title">SANCTUARY STORE</p>
                  <p className="cards-txt">Increasing Health Span</p>
                  <span className="cards-desc">
                    Well-being Services and Supplies
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="list-info">
        <Container>
          <Row className="align-items-center">
            <Col md={7} className="text-center">
              <p className="lrg-txt">
                Urban Sanctuaries for Conscious Creatures
              </p>
            </Col>
            <Col md={5} className="bio-text">
              <div className="list-img">
                <a href="http://www.biosphericallycorrect.org/">
                  <img alt="not found" src={Biospherically} />
                </a>
              </div>

              <div className="list-desc">
                The Sanctuary Project is being designed via Biospherically
                Correct principles.
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="sec-wrapper img-grid-c">
        <Row
          className="align-items-center ml-0 mr-0"
          style={{ justifyContent: "start" }}
        >
          <Col md={4} className="pl-0 pr-0">
            <div className="img-with-txt card-lff h-100">
              <img
                alt="not found"
                src={leafImg}
                className="h-100 w-100 leaf-img"
              />
              <div
                className="img-txt"
                onClick={() => {
                  window.open(
                    "https://sanctuarystore.co.nz/shop-by-product-and-services/raw-organic-ingredients",
                    "_blank"
                  );
                }}
              >
                Life Force Food
              </div>
              {/* <button className="btn img-btn">Read More </button> */}
            </div>
          </Col>
          <Col md={7}>
            <div className="txt-details">
              <p>
                The Urban Sanctuary is a work in progress Initiative from the
                Vision for Humanity Charitable Trust. It is being designed to
                provide a full range of Human Advancement services focused on
                Health, Prosperity, Inspiration and Joy. These four principles
                interlock the framework of our Human Advancement Architecture
                illustrated by our Metawheel, a model focused on your human
                magnificence, and to advance the planet.
                <br />
                <br />
                Under this context the centre houses well-being resources and
                facilitators, makes the Vision, Biospherically Correct, product
                range and the Life Force Food range, is the development hub for
                our Apps, literature, workshops, courses, and our On Demand
                Longevity Exercise series. Access the first resources here:{" "}
                <span
                  class="graytext fw-bold cursor-pointer"
                  onClick={() => dbLinks("/guide-landing-page")}
                >
                  Health Psychology
                </span>
                , the Principal Health Guide of the Urban Sanctuary’s series of
                six health guides, and the{" "}
                <span
                  class="graytext fw-bold cursor-pointer"
                  onClick={() => dbLinks("/package-health")}
                >
                  Health Directive Program
                </span>{" "}
                a comprehensive wellness course for greater health Span.
              </p>
              {/* <div className="icon-wrapper">
                <div className="setion-name">
                  <img alt="not found" src={Cluster1} className="img-fluid" />
                  <p className="greentext">Health</p>
                </div>

                <div className="setion-name">
                  <img alt="not found" src={Cluster4} />
                  <p className="blueText">Prosperity</p>
                </div>

                <div className="setion-name">
                  <img alt="not found" src={Cluster2} />
                  <p className="purpletext">Inspiration</p>
                </div>

                <div className="setion-name">
                  <img alt="not found" src={Cluster3} />
                  <p className="yellowText">Joy</p>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>

      <div className="sec-wrapper  img-grid-c">
        <Row className="align-items-center ml-0 mr-0">
          <Col md={4} className="grid-wrap">
            <div className="img-with-txt ">
              <img alt="not found" src={card1} className="w-100 img-fluid" />
              <div
                className="img-txt"
                onClick={() => dbLinks("/holistic-system")}
              >
                Advanced Holistic <br />
                Testing & Apps
              </div>
              {/* <button className="btn img-btn">Read More </button> */}
            </div>
          </Col>
          <Col md={4} className="grid-wrap">
            <div className="img-with-txt">
              <img alt="not found" src={card2} className="w-100 img-fluid" />
              <div
                className="img-txt"
                onClick={() => dbLinks("/advance-Exercises")}
              >
                Advancement Exercises
              </div>

              {/* <button className="btn img-btn">Read More </button> */}
            </div>
          </Col>
          <Col md={4} className="grid-wrap">
            <div className="img-with-txt">
              <img alt="not found" src={card3} className="w-100 img-fluid" />
              <div
                className="img-txt"
                onClick={() => {
                  window.open("https://visionproductsdirect.com/", "_blank");
                }}
              >
                <span className="span1">
                  Natural Biospherically <br />
                  Correct Products
                </span>
                <span className="span2">Vision Products</span>
              </div>

              {/* <button className="btn img-btn">Read More </button> */}
            </div>
          </Col>
          <Col md={6} className="grid-wrap">
            <div className="img-with-txt">
              <img alt="not found" src={card4} />
              <div
                className="img-txt"
                onClick={() => dbLinks("/health-facilitators")}
              >
                <span className="span1">In-house Health Facilitators</span>
                <span className="span2">Protocols & Consultancy</span>
              </div>
              <div className="bottom-txt">
                {/* <button className="btn img-btn">Read More </button> */}
              </div>
            </div>
          </Col>
          <Col md={6} className="grid-wrap">
            <div className="img-with-txt">
              <img alt="not found" src={card5} />
              <div
                className="img-txt"
                onClick={() => dbLinks("/package-health")}
              >
                <span className="span1">Advanced Learning</span>
                <span className="span2">Guides, Workshops & Courses</span>
              </div>
              <div className="bottom-txt">
                <button
                  className="btn img-btn"
                  onClick={() => dbLinks("/advance-exercises-guide")}
                >
                  Read More{" "}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="img-slides">
        <Slider {...settings}>
          <div className="position-relative">
            <img alt="not found" src={mobileBg} />
            <h3>
              OUR <br />
              <div className="spn-txt">APPLICATIONS</div>
            </h3>
          </div>
          <div className="position-relative">
            <img alt="not found" src={mobileBg} />
            <h3>
              OUR <br />
              APPLICATIONS
            </h3>
          </div>
          <div className="position-relative">
            <img alt="not found" src={mobileBg} />
            <h3>
              OUR <br />
              APPLICATIONS
            </h3>
          </div>
        </Slider>
      </div>
      <div className="our-story">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="text-center">
              <img alt="not found" src={globeHands} />
            </Col>
            <Col md={6}>
              <div className="story-txt">
                <div className="story-title">Something greater</div>
                <p className="story-desc">
                  The VFH Sanctuaries work together as a Biospherically Correct
                  eco system, providing you a stream of organic foods, ethical
                  products and personal development services, all based around
                  bringing out the magnificence in you. From our Charter we
                  promote that ‘we are all creators, with a life purpose to be
                  our fullest capacity and most magnificent expression’. The
                  Sanctuaries also interlock your advancement into planetary
                  wellbeing. Through their greater purpose and ethos, the
                  Sanctuaries show the path of greater people for better planet.
                  See our Global Metawheel.
                </p>
                <p className="story-desc">
                  Consciously shop online or at our Sanctuary Stores, personally
                  advance and grow through our Urban Sanctuary services, enjoy
                  the bliss and serenity of our Master Sanctuary, engage with
                  other conscious creatures on our Higher Consciousness Network
                  and ultimately prosper through ‘right livelihood’ with our
                  Plato Franchise System opportunity.
                </p>

                <div className="social-icons">
                  <p className="mb-0 font-weight-bold text-lg-left">
                    Discover your Magnificence at the Sanctuary
                  </p>

                  <div className="icons-cover">
                    <a href="https://www.instagram.com/vfhsanctuaries/"> <i className="fab fa-instagram"></i> </a>
                    {/* <i className="fab fa-twitter"></i>
                    <i className="fab fa-facebook-f"></i>
                    <img alt="not found" src={web} /> */}
                  </div>
                </div>
                <br />

                <p>
                  Find out more in{" "}
                  <span
                    class="graytext fw-bold cursor-pointer"
                    onClick={() => dbLinks("/about-us")}
                  >
                    About Us
                  </span>
                  .
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="map-info">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <div className="map-title">
                PROSPER - THE WORLD IS YOUR OYSTER
              </div>
              <p className="map-desc">
                Join the higher consciousness revolution, and utilize the Plato
                Franchise System
                <br />
                to establish a meaningful livelihood.{" "}
                <a href="https://sanctuarystore.co.nz/prosper"> Find out how to apply here</a>
              </p>
            </Col>
            <Col md={12} className="text-center map-tab">
              <Tabs defaultActiveKey="first">
                <Tab eventKey="first" title="World">
                  <div className="table-data">
                    <img alt="not found" src={map} />
                  </div>
                </Tab>
                <Tab eventKey="second" title="Australia & New Zealand">
                  <div className="chart-sec">
                    <img alt="not found" src={map} />
                  </div>
                </Tab>
                <Tab eventKey="third" title="Auckland">
                  <div className="chart-sec">
                    <img alt="not found" src={map} />
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="content-wrapper">
        <Container>
          <Row>
            <Col md={6} className="txt-wrapper">
              <div className="content-heading">THE PLATO FRANCHISE SYSTEM</div>
              <p>
                Participate in our Plato Franchise opportunity and prosper while
                being instrumental in helping create “A Successful Planet”.
              </p>
              <p>
                Plato, born 427 BC, a philosopher in Classical Greece, became
                the founder of the Academy in Athens, the first institution of
                higher learning in the Western world. Plato has become widely
                considered as one of the most respected thinkers of all time.
                One of Plato’s philosophies was that a person’s wage should not
                be more than 5 times that of another's. Plato was thinking of
                the power structures of the era, however to help establish a
                sense of equality in the world, we believe this thinking is now
                more important than ever.
              </p>
            </Col>
            <Col md={6}>
              <button
                className="btn inquire-btn"
                onClick={() =>
                  window.open("https://sanctuarystore.co.nz/prosper", "_blank")
                }
              >
                ENQUIRE ABOUT THIS
              </button>
              <p>
                Share your skills and passions with others, form your syndicate,
                build your teams ability, establish each person's role on our
                operational template, then have your chosen team leader submit
                your syndicates application to us. If you and your team are
                approved we will help you open your very own Sanctuary Store.
              </p>
              <p>
                We believe the Sanctuary and the Plato Franchise System
                opportunity is the way of the future; creating ecologically and
                financially sustainable pathways forward while collaborating
                people. Find out more here.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="men-grp">
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={8} className="grp-wrapper">
              <div className="grp-heading">
                GREATER PEOPLE FOR BETTER PLANET
              </div>
              <p className="grp-desc">
                Join our growing number of Visionary Urban Sanctuary customers
              </p>

              <span className="btn-1">Natural Beauty</span>

              <span className="btn-1">Increased Vitality</span>

              <span className="btn-1">Esteem and Appearance</span>

              <span className="btn-1">Improved Health</span>

              <span className="btn-1">Greater Prosperity</span>

              <span className="btn-1">Better Relationships </span>

              <span className="btn-1 bg-light-custom">
                Life Balance and Connection
              </span>

              <span className="btn-1">Empowerment</span>

              <span className="btn-1">Greater Purpose</span>

              <span className="btn-1">Clarity of Mind</span>

              <span className="btn-1">Greater Happiness</span>
            </Col>
            <Col md={7}>
              <div className="white-bg-txt ">
                <div className="d-flex icon-info justify-content-between">
                  <div className="name">Jean Shrimpton</div>
                  <div className="icons-cover d-flex align-items-center">
                    <i className="fab fa-instagram"></i>
                    <i className="fab fa-twitter"></i>
                    <i className="fab fa-facebook-f"></i>
                    <img alt="not found" src={web} />
                  </div>
                </div>
                <p>
                  Since shopping at the Sanctuary Store and eating truly good
                  food every day, I cannot believe the difference this has made
                  to my energy levels and work performance. I am definitely
                  recommending Sanctuary to all of my friends. What a great
                  concepts, thank you Sanctuary Store!
                </p>
              </div>
              <p className="norm-txt">Discover your Magnificence</p>
            </Col>
          </Row>
        </Container>
      </div> */}
      <PeopleSection peopleData={ PeopleData } />
      <Disclaimer />
      <FooterBanner />
      <Footer />
    </div>
  );
};

export default Home;
