import React from 'react';
import Slider from "react-slick";
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
import sigment1 from '../../assets/images/exercise_air_detail/sigment1.png';
import sigment2 from '../../assets/images/exercise_air_detail/sigment2.png';
import sigment3 from '../../assets/images/exercise_air_detail/sigment3.png';
import sigment4 from '../../assets/images/exercise_air_detail/sigment4.png';
import sigment5 from '../../assets/images/exercise_air_detail/sigment5.png';
import sigment6 from '../../assets/images/exercise_air_detail/sigment6.png';
import sigment7 from '../../assets/images/exercise_air_detail/sigment7.png';
import { Box, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
// import { TabContext, TabPanel } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  segmentVideo: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: '15px',
    border: '1px solid white',
  },
}));

const DEFAULT_IMAGES = [
  {
    title: "Okinawan Garden",
    url: sigment1,
    mediaType: "image",
  },
  {
    title: "Tropical Beach",
    url: sigment2,
    mediaType: "image",
  },
  {
    title: "New York loft",
    url: sigment3,
    mediaType: "image",
  },
  {
    title: "Warehouse",
    url: sigment4,
    mediaType: "image",
  },
  {
    title: "High Tech Studio",
    url: sigment5,
    mediaType: "image",
  },
  {
    title: "Forest Water Fall",
    url: sigment6,
    mediaType: "image",
  },
  {
    title: "Lake",
    url: sigment7,
    mediaType: "image",
  },
  {
    title: "High Tech Studio",
    url: sigment5,
    mediaType: "image",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`segment-tabpanel-${index}`}
      aria-labelledby={`segment-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const SegmentItem = ({ segment, onClick }) => {
  const classes = useStyles();

  return (
    <div className="sig-card" onClick={onClick}>
      <p>{segment.title}</p>

      {segment.mediaType === "image" ? (
        <img alt={segment.title} src={segment.url} />
      ) : (
        <video className={classes.segmentVideo} preload="metadata" height={220}>
          <source src={segment.url + "#t=20"} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

const ExerciseDetailSigmentTab = ({
  segments, sequences,
  onSegmentChange,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const handleClick = (segmentIdx) => {
    if (onSegmentChange) {
      onSegmentChange(activeTab, segmentIdx);
    }
  };

  const handleTabChange = (e, newValue) => {
    console.log("tab change", newValue);
    setActiveTab(newValue);
  };

  var sigCard = {
    autoplay: true,
    dots: false,
    infinite: false,
    autoplaySpeed: 1500,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
    <>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab label="Select Sequences" aria-controls="segment-tabpanel-0" />
        <Tab label="Select Segments" aria-controls="segment-tabpanel-1" />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <Slider {...sigCard}>
          {(sequences && sequences?.length > 0 ? sequences : DEFAULT_IMAGES).map((segment, segmentKey) => (
            <SegmentItem key={segmentKey} segment={segment} onClick={() => handleClick(segmentKey)} />
          ))}
        </Slider>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Slider {...sigCard}>
          {(segments && segments?.length > 0 ? segments : DEFAULT_IMAGES).map((segment, segmentKey) => (
            <SegmentItem key={segmentKey} segment={segment} onClick={() => handleClick(segmentKey)} />
          ))}
        </Slider>
      </TabPanel>
    </>

  );
}

export default ExerciseDetailSigmentTab;