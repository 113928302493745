import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Header from "../../components/Header/Header";
import { Modal, Button, Form } from "react-bootstrap";
import AdvanceBannerSlider from "../../components/AdvanceBannerGuideSlider/AdvanceBannerGuideSlider";
import Cluster1 from "../../assets/images/Cluster-1.png";
import Cluster2 from "../../assets/images/Cluster-2.png";
import Cluster3 from "../../assets/images/Cluster-3.png";
import Cluster4 from "../../assets/images/Cluster-4.png";
import ServiceItem from '../../components/service-item';
import GreenDisk from "../../assets/images/health-package/greenDisk.png";
import bookthumbnail from "../../assets/images/health-package/bookInFlowers.jpg"
import Stars from "../../assets/images/guide/stars.png";
import CustomerReviews from '../../components/customer-reviews';
import HealthImg1 from '../../assets/images/advance-guide/health1.png';
import HealthImg2 from '../../assets/images/advance-guide/health2.png';
import HealthImg3 from '../../assets/images/advance-guide/health3.png';
import HealthImg4 from '../../assets/images/advance-guide/health4.png';
import HealthImg5 from '../../assets/images/advance-guide/health5.png';
import HealthImg6 from '../../assets/images/advance-guide/health6.png';
import couple02 from '../../assets/images/health-package/couple02.png';
import InsImg1 from '../../assets/images/advance-guide/Ins1.png';
import InsImg2 from '../../assets/images/advance-guide/Ins2.png';
import InsImg3 from '../../assets/images/advance-guide/Ins3.png';
import InsImg4 from '../../assets/images/advance-guide/Ins4.png';
import InsImg5 from '../../assets/images/advance-guide/Ins5.png';
import InsImg6 from '../../assets/images/advance-guide/Ins6.png';

import InspImg1 from '../../assets/images/advance-guide/Insp1.png';
import InspImg2 from '../../assets/images/advance-guide/Insp2.png';
import InspImg3 from '../../assets/images/advance-guide/Insp3.png';
import InspImg4 from '../../assets/images/advance-guide/Insp4.png';
import InspImg5 from '../../assets/images/advance-guide/Insp5.png';
import InspImg6 from '../../assets/images/advance-guide/Insp6.png';
import JoyImg1 from '../../assets/images/advance-guide/joy1.png';
import JoyImg2 from '../../assets/images/advance-guide/joy2.png';
import JoyImg3 from '../../assets/images/advance-guide/joy3.png';
import JoyImg4 from '../../assets/images/advance-guide/joy4.png';
import JoyImg5 from '../../assets/images/advance-guide/joy5.png';
import JoyImg6 from '../../assets/images/advance-guide/joy6.png';
import Metawheel1 from '../../assets/images/advance-guide/metawheel1.png';
import Metawheel2 from '../../assets/images/advance-guide/metawheel2.png';
import Metawheel3 from '../../assets/images/advance-guide/metawheel3.png';
import Metawheel4 from '../../assets/images/advance-guide/metawheel4.png';
import Metawheel5 from '../../assets/images/advance-guide/metawheel5.png';
import Metawheel6 from '../../assets/images/advance-guide/metawheel6.png';
import applogo from '../../assets/images/advance-guide/app-logos.png';
import Star from '../../assets/images/advance-guide/star.png';
import playerIcon from '../../assets/images/player-icon.png';
import FooterBanner from '../../components/footer-banner';
import Kim from '../../assets/images/advance-guide/kim-img.png';
import userImg from '../../assets/images/advance-guide/user-img.png';
import Video from '../../assets/images/advance-guide/video.png';
import Anthea from '../../assets/images/reviews/Anthea.jpeg'
import Graeme from '../../assets/images/reviews/Graeme.png'
import Mike from '../../assets/images/reviews/Mike.png'
import Mari from '../../assets/images/reviews/Mari.jpg'
import IvanM from "../../assets/images/reviews/IvanM.jpg";
//import ph_ppbw from '../../assets/images/reviews/placeholder_pp_bg-removed-bw.png'
import "./AdvanceExercisesGuide.scss";
import GuideModal from '../../components/guide-modal';
import Footer from '../../components/Footer/Footer';
import ClockIcon from "../../assets/images/guide/clockIcon.png";
import LangIcon from "../../assets/images/guide/langIcon.png";

const AdvanceExercisesGuide = () => {
  const [scrollPage, setOffset] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const dbLinks = (link) => {
    history.push(link)
  }
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  let guidesHealth = [
    { name: 'HEALTH PSYCHOLOGY', subname: "Principle Health Guide and Book available now", image: HealthImg1 },
    { name: 'LIFE FORCE FOOD', subname: "Advance Order", image: HealthImg2 },
    { name: 'LONGEVITY ACTIVITIES', subname: "Advance Order", image: HealthImg3 },
    { name: 'PHYSIOLOGICAL EFFICIENCIES', subname: "Advance Order", image: HealthImg4 },
    { name: 'NATURAL REMEDIES', subname: "Advance Order", image: HealthImg5 },
    { name: `PURITY`, subname: "Advance Order", image: HealthImg6 },

  ]
  let guidesIns = [
    { name: 'Contextual Intelligence', subname: "Advance Order", image: InsImg1 },
    { name: 'Experiential Learning ', subname: "Advance Order", image: InsImg2 },
    { name: 'Key Literacy’s', subname: "Advance Order", image: InsImg3 },
    { name: 'Unique Passion Flow', subname: "Advance Order", image: InsImg4 },
    { name: 'Mastermind success principles', subname: "Advance Order", image: InsImg5 },
    { name: `Ethical Entrepreneurship`, subname: "Advance Order", image: InsImg6 },
  ]
  let guidesPross = [
    { name: 'Conscious Communication', subname: "Advance Order", image: InspImg1 },
    { name: 'Soul Mate Nurture', subname: "Advance Order", image: InspImg2 },
    { name: 'New Born Nurture', subname: "Advance Order", image: InspImg3 },
    { name: 'Advanced Parenting', subname: "Advance Order", image: InspImg4 },
    { name: 'Abundant family Living', subname: "Advance Order", image: InspImg5 },
    { name: `Significant Media`, subname: "Advance Order", image: InspImg6 },
  ]
  let guidesJoy = [
    { name: 'Source', subname: "Advance Order", image: JoyImg1 },
    { name: 'Authenticity & Personal organising principles', subname: "Advance Order", image: JoyImg2 },
    { name: 'Godforce', subname: "Advance Order", image: JoyImg3 },
    { name: 'capacity & service', subname: "Advance Order", image: JoyImg4 },
    { name: 'magnanimity & grace', subname: "Advance Order", image: JoyImg5 },
    { name: `vibrational transcendence`, subname: "Advance Order", image: JoyImg6 },
  ]
  let Endorsement = [
    { name: 'Discovery Weekend', subTitle: "", image: Metawheel1, btn: 'Pre-Course', titColor: '' },
    { name: 'Vitality and Longevity ', subTitle: "Health", image: Metawheel2, btn: 'Main-Course', titColor: 'colr1' },
    { name: 'Wisdom and Successes ', subTitle: "Prosperity ", image: Metawheel3, btn: 'Main-Course', titColor: 'colr2' },
    { name: 'Empowerment and Significance ', subTitle: "Inspiration ", image: Metawheel4, btn: 'Main-Course', titColor: 'colr3' },
    { name: 'Clarity and Beauty', subTitle: "Joy", image: Metawheel5, btn: 'Main-Course', titColor: 'colr4' },
    { name: `Metawheel Package`, subTitle: "", image: Metawheel6, btn: 'Course Pkg', titColor: '' },
  ]
  const [activeTab, setActiveTab] = useState('GUIDES');
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const updateGuideState = (tab) => {
    setActiveTab(tab);
  }

  const handleShow = (title, image) => {
    setTitle(title);
    setImage(image);
    setShow(true);
  }
  const closeModal = () => {
    setShow(false);
  }

  const renderTabs = () => {
    let tabs = ['GUIDES', 'WORKSHOPS', 'COURSES'];

    return (
      <div id="guide_workshop_course" className="table_body_head">
        {tabs.map(item => {
          return <div className={`table_body_head_item ${activeTab === item ? 'active' : ''}`} onClick={() => setActiveTab(item)}>{item}</div>
        })}
      </div>
    )
  }
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 1200,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const reviews = [
    {
      ppic: Anthea,
      caption: 'Well researched',
      stars: 4,
      customer: 'Anthea Baker',
      tab: 'Health Psychology Guide and Book',
      review: 'Comprehensive, highly educational and beneficial.',
      date: '20/03/2023'

    },
    {
      ppic: Graeme,
      caption: 'A great read and aid to my health advancement',
      stars: 5, customer: 'Graeme Lamond',
      customerTitle: 'Business owner',
      tab: 'Health Psychology Guide and Book',
      review: 'Great comprehensive book and not too over complicated so that it was relatable and wasn’t dull like so many course books…..definitely a bonus to read prior to starting the Health Directive Program.',
      date: '24/05/2023'

    },
    {
      ppic: IvanM,
      caption: 'Exceptional, comprehensive and highly beneficial.',
      stars: 5,
      customer: 'Ivan Middlemost',
      customerTitle: 'Chartered accountant',
      tab: 'Health Psychology Guide and Book',
      review: 'The book is very detailed and comprehensive, but explains material in a lay-person and easy to understand manner. Exceptional, well researched and presented material. I took out what was important to me and what I enjoyed most was the feeling of advancement.',
      date: '25/04/2023'

    },
    {
      ppic: Mike,
      caption: 'Solid research.',
      stars: 5,
      customer: 'Mike Lough',
      customerTitle: 'Business owner',
      tab: 'Health Psychology Guide and Book',
      review: 'A great read and aid to my health advancement.',
      date: '05/07/2023'

    },
    {
      ppic: Mari,
      caption: '…opened my eyes to see the value in the book.',
      stars: 4.5,
      customer: 'Mari Gordon',
      tab: 'Health Psychology Guide and Book',
      review: 'The more Kim explained the content, the more I began to see the value in it, in fact the knowledge provided is critical for overall well-being. You just need to make the effort to read and engage with it and then you will have “AHA” moments.',
      date: '18/07/2023'

    }


  ]
  return (
    <div className="advance-Exercise-guide page-w">
      <Header />
      <AdvanceBannerSlider updateGuideState={updateGuideState} />
      <Row>
        <Col sm={12} md={12}>
          <div className="botom-icon">
            <div className="setion-name mr-5">
              <p><b>All</b></p>
            </div>
            <div className="setion-name" onClick={() => window.location.href = "#health"}>
              <img src={Cluster1} alt="Cluster1" />
              <p>Health</p>
            </div>
            <span>|</span>
            <div className="setion-name" onClick={() => window.location.href = "#prosperity"}>
              <img src={Cluster4} alt="Cluster2" />
              <p>Prosperity</p>
            </div>
            <span>|</span>
            <div className="setion-name" onClick={() => window.location.href = "#inspiration"}>
              <img src={Cluster2} alt="Cluster3" />
              <p>Inspiration</p>
            </div>
            <span>|</span>
            <div className="setion-name" onClick={() => window.location.href = "#joy"}>
              <img src={Cluster3} alt="Cluster4" />
              <p>Joy</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <div className="title-head">
            {renderTabs()}
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className='section2'>
            <div id="health" className='section2_subTitle'>Health</div>
            {activeTab === "GUIDES" &&
              <div className='list' >
                {guidesHealth.map(item => {
                  return (
                    <div
                      key={item.name}
                      onClick={() => item.name != "HEALTH PSYCHOLOGY" ? handleShow(item.name, item.image) : dbLinks('/guide-landing-page')}
                    >
                      <ServiceItem cn='list_item' item={item} />
                    </div>
                  )
                })}
              </div>
            }
            {activeTab === "COURSES" &&
              <div className='courses'>
                <div onClick={() => dbLinks('/package-health')}>
                  <ServiceItem cn='courses_banner' item={{ name: 'Vitality and Longevity', followingLine: 'Health Directive Program', image: couple02 }} uppercase />
                </div>
                <div className='courses_desc'>
                  <div style={{ fontWeight: 'bold' }}>Healing, Prevention, Superior Health, Dynamic Shape, Greater Energy, Performance, Clarity and Emotional Vibrancy</div>
                  <p>Comprehensive, multifaceted and integrated health program to transform your health horizon.
                    Interlocks mental – emotional health, preventative, anti-aging and health span initiatives.
                    Advanced integrated health testing and analysis, coaching and monitoring to achieve favourable results.
                    This course draws from all six guides of the Vitality and Longevity series to provide you a complete well-being package
                    of knowhow and skills, that you can apply right away and use for life, to create a vibrant new you, and a Self-Advancing Life Style.</p>
                </div>
              </div>
            }
            {activeTab === "WORKSHOPS" &&

              <div className='list' style={{ overflow: 'unset' }}>
                <div className="card_details">
                  <div className="data_desc" style={{ color: "#B5B5B5" }}>
                    Workshop
                  </div>
                  <img className="card_details_thumbnail" src={bookthumbnail} alt="Health Psychology Book" />
                  <div className="data_desc">Health Psychology</div>
                  <div className="data_rating">
                    <img src={Stars} alt="Stars" />
                    <p>{`(102)`}</p>
                  </div>
                  <div className="data_author">
                    <b>Principle presenter:</b>&nbsp;&nbsp; <i>Kim Larking</i>
                  </div>
                  <p
                    className={
                      expanded
                        ? "card_details_desc expanded"
                        : "card_details_desc collapsed"
                    }
                  >
                    <b>Description:</b>This workshop distils the Health Psychology book
                    to help you navigate a path to greater happiness, well-being and
                    joy. With practical lessons to gain the insights, personal
                    confidence and ability to implement numerous Tools and Techniques to
                    start positively transforming your life right away - creating a New
                    You and engineering a highly rewarding Self-Advancing Lifestyle.
                  </p>
                  <div className="readMore" onClick={() => toggleExpand()}>
                    {expanded ? "Read Less" : "Read More"}
                  </div>
                  {/* <div className="card_details_price">
                    $169.99 <span className="discount">&nbsp;&nbsp;$299.99</span>
                  </div> */}
                  <div className="card_list">
                    <div className="card_list_item">
                      <img src={ClockIcon} alt="icon" />

                      <div class="duration">
                        <b>Duration :</b> 5 x 1 hour live interactive modules
                        <p>
                          <i>(available now) includes e-book PDF</i>
                        </p>
                      </div>
                      <div className="card_details_price">
                        $499.00 <span className="discount">$799.00</span>
                      </div>
                      <div className="card_btn light cart-button">
                        <a href="mailto:kim@visionproducts.co.nz?subject=Advanced%20Learning%20Workshop%20-%20Interactive%20Module%20-%20Enquire">
                          Enquire Now
                        </a>
                      </div>
                    </div>
                    <div className="card_list_item">
                      <img src={ClockIcon} alt="icon" />

                      <div class="duration">
                        <b>Duration :</b> 5 x 1 hour online learning modules
                        <p>
                          <i>(coming soon) includes e-book PDF</i>
                        </p>
                      </div>
                      <div className="card_details_price">$129.00</div>
                      <div className="card_btn light cart-button">
                        <a href="mailto:kim@visionproducts.co.nz?subject=Advanced%20Learning%20Workshop%20-%20Learning%20Module%20-%20Enquire">
                          Enquire Now
                        </a>
                      </div>
                    </div>
                    <div className="card_list_item">
                      <img src={LangIcon} alt="icon" />
                      <div>
                        <b>Language :</b> English
                      </div>
                    </div>
                  </div>
                  <div className="card_btns">
                    <div className="card_btns_btn dark">
                      <a href="tel:+6421505170">
                        Contact Kim at +64 21 505 170 for a free 15 minute call
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            }
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className='section2'>
            <div id="prosperity" className='section2_subTitle'>Prosperity</div>
            {activeTab === "GUIDES" &&
              <div className='list'>
                {guidesIns.map(item => {
                  return (
                    <div
                      onClick={() => handleShow(item.name, item.image)}
                    >
                      <ServiceItem cn='list_item' item={item} />
                    </div>
                  )
                })}
              </div>
            }
            {(activeTab === "WORKSHOPS" || activeTab === "COURSES") &&
              <div style={{ minHeight: "250px" }}></div>
            }
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className='section2'>
            <div id="inspiration" className='section2_subTitle'>
              <div>Inspiration</div>
              {activeTab === "GUIDES" &&
                <div className='section2_subTitle_goldText'>The Sanctuary of Conscious Relationship</div>
              }
            </div>
            {activeTab === "GUIDES" &&
              <div className='list'>
                {guidesPross.map(item => {
                  return (
                    <div
                      onClick={() => handleShow(item.name, item.image)}
                    >
                      <ServiceItem cn='list_item' item={item} />
                    </div>
                  )
                })}
              </div>
            }
            {(activeTab === "WORKSHOPS" || activeTab === "COURSES") &&
              <div style={{ minHeight: "250px" }}></div>
            }

          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className='section2'>
            <div id="joy" className='section2_subTitle'>Joy</div>
            {activeTab === "GUIDES" &&
              <div className='list' >
                {guidesJoy.map(item => {
                  return (
                    <div
                      onClick={() => handleShow(item.name, item.image)}
                    >
                      <ServiceItem cn='list_item' item={item} />
                    </div>
                  )
                })}
              </div>
            }
            {(activeTab === "WORKSHOPS" || activeTab === "COURSES") &&
              <div style={{ minHeight: "250px" }}></div>
            }
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className='discover-section'>
            <img alt="not found" className="nactiveimg" src={applogo} />
            <h1>Discover your Magnificence with Metawheel</h1>
            <p>Analysis to pinpoint specific areas of growth that you need most to advance your holistic well-being.</p>
            <p>Unlock the pathways to your full Life-force, Holistic Human Vibrancy, Significance and Purpose</p>
            <h3>Discover, Shift, Evolve, Create</h3>
            <h4>Human Advancement Architecture</h4>
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className='customers'>
            <div className='customers_content'>

              <div className='customers_content_star'>
                <div className='customers_content_star_title'>
                  <img alt="not found" src={Star} title="WRITE REVIEWS" />
                  <div className='customers_content_head'>
                    <h1>Customer Reviews</h1>
                  </div>
                </div>
              </div>
              <CustomerReviews reviews={reviews} />

            </div>
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div className="cust-author2">
            <h1>About Author</h1>
            <Row>
              <Col sm={12} md={4}>
                <div className="author-img">
                  <img alt="not found" src={Kim} />

                </div>
              </Col>
              <Col sm={12} md={8}>
                <div className="author-txt">
                  <h2>Kim Larking</h2>
                  <p>Human Advancement Architect</p>
                  <p>Founder Vision for Humanity / Vision Products / The Sanctuary
                  Project</p>
                  <p>The imaginal cells within the caterpillar, intrinsic to its miraculous transformation into a butterfly, are also present within us. The human mind, akin to these cells, represents the creative powers of imagination, planning, and self-determination.</p>

                  <p>Just like the process of metamorphosis, the mind harbours the ability to transform our lives as we desire. By understanding and mastering the Mind-Body system, and by amplifying the body’s processes through advanced mental application, we can unlock evolution’s best-kept secrets to achieve greater holistic health. Through mastering a Self-Advancing Lifestyle, we initiate our own Health Evolution process. In doing so, we elevate our satisfaction and life success while contributing to a better planet and humanity.</p>

                  <p>We are the architects of our own selves, and therefore, we can define the person we wish to become. With focus and belief, we can materialise our dreams. Be the architect of your life and create an abundance of love, wealth, health, and happiness.</p>

                  <p>"Let me help you catalyse and architect your creation powers of imagination, planning & self-determination"</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {/* <Col sm={12} md={12}>
          <div className="cust-author1">
            <h1>Endorsement </h1>
            <Row>
              <Col sm={12} md={6}>
                <div className="author-img">
                  <img alt="not found" src={Video} />
                  <img className="player-icon" alt="not found" src={playerIcon} />
                </div>

              </Col>
              <Col sm={12} md={6}>
                <div className="author-txt">
                  <Row>
                    <Col sm={12} md={6}>
                      <div className="author-img1">
                        <img alt="not found" src={userImg} />
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="author-txt">
                        <h3>Kimbra</h3>
                        <p>Recording Artist/ Pop Star</p>
                        <p>“ Hi Kim, Kimbra here! Just wanted to write a quick note to say thank you for the lovely Vision products! Very kind of you and I wanted to just encourage you in the work you’re doing as it has been really nice to read about the backstory, ethics and ‘vision’ (so to speak!) behind your company and what you stand for. I appreciate you taking the time to share that with me, keep up the great work! ”</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col> */}
        <Col sm={12} md={12}>
          <div className="cust-author3">
            <h1>Metawheel Courses </h1>
            <div className='section2'>
              <div className='list' onClick={handleShow}>
                {Endorsement.map(item => {
                  return (
                    <ServiceItem cn='list_item' item={item} />
                  )
                })}
              </div>
            </div>

          </div>
        </Col>
      </Row>
      <FooterBanner />
      <Footer />
      <Modal show={show}>
        <GuideModal title={title} imgPath={image} closeModal={closeModal} />
      </Modal>
    </div>
  );
};
export default AdvanceExercisesGuide;
